import React from "react";
import { Formik, Form } from "formik";
import classNames from "classnames";

import styles from "./inventory.module.scss";

import AppContext from "../../context/AppContext";
import InventoryModal from "./InventoryModal";
import SearchBar from "../../components/Templates/Forms/SearchBar";
import Input from "../../components/Templates/Forms/Input";

const Menu = ({ appContext, searchData, resetSearch, initialProduct }) => (
  <AppContext.Consumer>
    {app => (
      <div className="columns">
        <div className="column is-8">
          <Formik
            initialValues={{
              employee: "",
              product: initialProduct || "",
            }}
          >
            {({ values }) => (
              <Form autoComplete="off">
                <div className="columns">
                  <div className="column">
                    {appContext.siteDetails.id && (
                      <Input
                        isRequired
                        name="product"
                        value="product"
                        render={({ field, form, props }) => {
                          return (
                            <SearchBar
                              placeholder="Search product..."
                              endpoint="inventory/report"
                              field={field}
                              form={form}
                              props={props}
                              itemDescription="products"
                              displayResults={false}
                              resultDisplay={["productTitle"]}
                              keyPressCallback={() => {
                                searchData(values.product);
                              }}
                              callbackFunction={result => {
                                searchData(result.productTitle);
                              }}
                              isExpanded
                            >
                              <div className="control">
                                <button
                                  name="search-button"
                                  className={classNames(
                                    "button is-primary",
                                    styles.searchButton
                                  )}
                                  onClick={() => {
                                    searchData(values.product);
                                  }}
                                >
                                  Search
                                </button>
                              </div>
                              <div className="control">
                                <button
                                  className={classNames("delete is-large", {
                                    "is-invisible": values.product.length < 1,
                                  })}
                                  onClick={resetSearch}
                                  type="reset"
                                />
                              </div>
                            </SearchBar>
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="column">
          <button
            className="button is-light is-pulled-right"
            disabled
            onClick={() =>
              appContext.useModal(
                <InventoryModal
                  tallyName="manualTally"
                  modalType="adjusted"
                  tallyLabel="Manual Tally"
                  buttonColor="light"
                />,
                "",
                "MANUAL INVENTORY"
              )
            }
          >
            +/- Manual Count
          </button>
        </div>
      </div>
    )}
  </AppContext.Consumer>
);

export default Menu;
