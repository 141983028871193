import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, ErrorMessage } from "formik";

const HorizontalInput = ({
  label,
  type,
  placeholder,
  value,
  min,
  name,
  isRequired,
  render,
  isDisabled,
  className,
  isReadOnly,
  labelClassName,
}) => {
  const propsObject = {
    className: classNames("input", className),
    type,
    min,
    name,
    placeholder,
    value,
    disabled: isDisabled,
    readOnly: isReadOnly,
    labelClassName,
    isRequired,
  };
  return (
    <Fragment>
      <div className={classNames("field is-horizontal")}>
        {!!label && (
          <label
            className={classNames(
              "field-label mt-1 mr-1 has-text-left",
              {
                "has-text-weight-normal has-text-grey-dark": !isRequired,
              },
              { "has-text-weight-bold": isRequired },
              labelClassName
            )}
          >
            {label}
            {!!isRequired && <span className="has-text-danger"> *</span>}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <p className="control">
              {render ? (
                <Field {...propsObject} render={props => render(props)} />
              ) : (
                <Field
                  {...propsObject}
                  onKeyDown={event => {
                    if (type === "number" && event.key === "e")
                      event.preventDefault();
                  }}
                  onPaste={event => {
                    if (
                      type === "number" &&
                      event.clipboardData.getData("text").includes("e")
                    )
                      event.preventDefault();
                  }}
                />
              )}
              <ErrorMessage name={name}>
                {error => (
                  <div className="has-text-danger is-size-7">{error}</div>
                )}
              </ErrorMessage>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const horizontalInputPropTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

HorizontalInput.propTypes = horizontalInputPropTypes;

export default HorizontalInput;
