import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const NewButton = () => {
  return (
    <div className="dropdown is-hoverable is-right">
      <div className="dropdown-trigger">
        <button
          className="button is-primary is-medium"
          aria-haspopup="true"
          aria-controls="dropdown-menu4"
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="has-text-5 ml-1 mr-5">New</span>
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu4" role="menu">
        <div className="dropdown-content">
          <Link to="/consultations/add">
            <div className="dropdown-item px-2 py-1 content is-medium">
              Consultation
            </div>
          </Link>
          <Link to="/medcerts/add">
            <div className="dropdown-item px-2 py-1 content is-medium">
              Medical Certificate
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewButton;
