import React from "react";
import classNames from "classnames";

import styles from "../inventory.module.scss";

const ReplenishmentRow = ({ item, index, callbackFunction, isLoading }) => {
  let {
    productTitle,
    dailyAverage,
    currentQuantity,
    daysRemaining,
    replenishQuantity,
  } = item;

  return (
    <tr
      className={classNames({
        "has-text-danger has-text-weight-bold": daysRemaining < 90,
      })}
      onClick={callbackFunction}
      key={index}
    >
      <td className={classNames(styles.medicine)}>{productTitle}</td>
      <td className="is-narrow has-text-centered">{dailyAverage}</td>
      <td className="is-narrow has-text-centered">{currentQuantity}</td>
      <td className="is-narrow has-text-centered">{daysRemaining}</td>
      <td className="is-narrow has-text-centered">{replenishQuantity}</td>
      <td
        className="has-text-centered has-tooltip"
        data-tooltip={`Days remaining: ${daysRemaining}`}
      >
        <progress
          className={classNames("progress is-primary", {
            "is-danger": daysRemaining < 90,
          })}
          value={daysRemaining}
          max={180}
        >
          {daysRemaining}
        </progress>
      </td>
    </tr>
  );
};

export default ReplenishmentRow;
