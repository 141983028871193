import React, { useEffect } from "react";

import Layout from "../layout/Layout";
import SEO from "../layout/Seo";
import NewButton from "../../NewConsultationMedcert";
import InventoryLogs from "../components/Inventory/InventoryLogs";

export default () => {
  let clinicId;
  if (typeof window !== `undefined`) {
    clinicId = window.sessionStorage.getItem("clinicId");
  }

  return (
    <Layout hasBanner pageTitle="Inventory" hasRightButton={<NewButton />}>
      <SEO title="Inventory" />
      <InventoryLogs clinicId={clinicId} />
    </Layout>
  );
};
