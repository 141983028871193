import React, { useContext, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import classNames from "classnames";

import styles from "./inventory.module.scss";

import AppContext from "../../context/AppContext";

import HorizontalInput from "../Templates/Forms/HorizontalInput";
import Input from "../Templates/Forms/Input";
import SearchBar from "../Templates/Forms/SearchBar";
import Columns from "../../layout/Columns";
import Column from "../../layout/Column";

import { InventoryValidationSchema } from "../Templates/Forms/ValidationSchema";
import api from "../../services/api";

// insert function for handleSubmit

export const PureInventoryAdjustmentForms = ({
  currentTally,
  values,
  finalTally,
  tallyLabel,
  modalType,
  computeFinalTally,
}) => {
  const [isActive, setActivebutton] = useState("Adjusted");

  const setAdjustmentReason = (values, value) => event => {
    setActivebutton(value);
    return values;
  };

  return (
    <Columns>
      <Column className="is-12 mt-2">
        {/* Current tally */}
        <div className={classNames("field is-horizontal")}>
          <label>Automated Stocks Left</label>
          <div className="field-body">
            <div className="field">
              <p className="control has-text-right">
                <input
                  readOnly
                  value={currentTally}
                  className="input has-text-right is-static"
                />
              </p>
            </div>
          </div>
        </div>
        {/* To be added/subtracted / Manual tally */}
        <HorizontalInput
          value={values.Quantity}
          name="Quantity"
          type="number"
          label="Actual Count"
          className="has-text-right"
          labelClassName={styles.tallyLabels}
          onChange={computeFinalTally(values)}
          min="0"
          isRequired
        />
        <HorizontalInput
          value={values.expirationDate}
          name="expirationDate"
          type="month"
          label="Earliest Expiration Date"
          className="has-text-right"
          labelClassName={styles.tallyLabels}
        />

        {modalType === "adjusted" && (
          <>
            <div className="field">
              <label className="label has-text-left tally-labels">
                Reason for Adjustment
                <span className="has-text-danger"> *</span>
              </label>
              <div className="field-body">
                <div className="field has-addons">
                  <p className="control">
                    <button
                      type="button"
                      className={classNames("button", {
                        "is-primary": isActive === "Adjusted",
                      })}
                      onClick={setAdjustmentReason(values, "Adjusted")}
                    >
                      Recounted
                    </button>
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      className={classNames("button", {
                        "is-primary": isActive === "Damaged",
                      })}
                      onClick={setAdjustmentReason(values, "Damaged")}
                    >
                      Damaged
                    </button>
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      className={classNames("button", {
                        "is-primary": isActive === "Replenished",
                      })}
                      onClick={setAdjustmentReason(values, "Replenished")}
                    >
                      Replenished
                    </button>
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      className={classNames("button", {
                        "is-primary": isActive === "Expired",
                      })}
                      onClick={setAdjustmentReason(values, "Expired")}
                    >
                      Expired
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="field-body">
              <div className="field">
                <div
                  className={classNames(
                    "control has-text-right",
                    styles.customMargin
                  )}
                  id="reason"
                />
              </div>
            </div>
          </>
        )}

        {/* Final tally */}
        <Columns
          className={classNames(
            "my-2 has-text-weight-bold",
            {
              "has-background-white-ter": modalType !== "adjusted",
            },
            { "has-background-light": modalType === "adjusted" }
          )}
        >
          <Column>
            <label
              className={classNames({
                "has-text-primary": modalType === "replenished",
                "has-text-warning": modalType === "subtracted",
              })}
            >
              Final count{" "}
            </label>
          </Column>
          <Column className="has-text-right">{finalTally}</Column>
        </Columns>
      </Column>
    </Columns>
  );
};

// set initial values via const and pass as props
const InventoryModal = ({
  modalType,
  tallyLabel,
  buttonColor,
  productTitle,
  actualCount,
  expectedCount,
  productCode,
  earliestExpiry,
  clinicId,
  loadInventory,
  setLoadingIndex,
  currentIndex,
}) => {
  // const [error, setError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const appContext = useContext(AppContext);
  const [currentProduct, setCurrentProduct] = useState("");
  const [currentTally, setCurrentTally] = useState(0);
  const [finalTally, setFinalTally] = useState(currentTally);
  const [isLoading, setLoading] = useState(false);
  const updated = true;

  const computeFinalTally = values => {
    switch (modalType) {
      case "replenished":
        return setFinalTally(currentTally + values.Quantity);
      case "subtracted":
        return setFinalTally(currentTally - values.Quantity);
      case "adjusted":
      default:
        return setFinalTally(!!values.Quantity ? values.Quantity : actualCount);
    }
  };

  useEffect(() => {
    if (expectedCount) setCurrentTally(expectedCount);
    if (productCode) setCurrentProduct(productCode);
  }, []);

  useEffect(() => {
    if (!expectedCount) {
      const fetchCurrentTally = () => {
        api
          .get(`inventory/?search=${currentProduct}`)
          .then(response => {
            setCurrentTally(response.data.results[0].currentQuantity);
          })
          .catch(error => console.log(error));
      };
      if (!!currentProduct) return fetchCurrentTally();
    }
  }, [currentProduct]);

  const initialValues = {
    reason: "adjusted",
    Quantity: actualCount,
    created: null,
    productTitle: productTitle || "",
    expirationDate: earliestExpiry || null,
  };

  const handleSubmit = (closeModal, useToast) => values => {
    setLoading(true);
    setLoadingIndex(currentIndex);
    if (!!currentProduct) {
      api
        .post("/inventory_logs/", reconstructValues(values), {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        })
        .then(() => {
          setLoading(false);
          closeModal();
          // navigate("/inventory");
          useToast(
            `Successfully adjusted ${values.productTitle}.`,
            "is-success",
            5000
          );
          loadInventory(updated);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1500);
        })
        .catch(error => {
          setLoading(false);
          useToast("Something went wrong.", "is-danger", 5000);
        });
    } else {
      setLoading(false);
      useToast("Please select a product.", "is-danger", 5000);
    }
  };

  const reconstructValues = values => ({
    created: moment()
      .utc()
      .format(),
    product_code: currentProduct,
    quantity: values.Quantity,
    reason: values.reason,
    updated_by: parseInt(sessionStorage.getItem("staffId")),
    earliestExpirationDate: values.expirationDate
      ? moment(values.expirationDate)
          .startOf("month")
          .format("YYYY-MM-DD")
      : null,
    clinic: clinicId,
  });

  return (
    <AppContext.Consumer>
      {app => (
        <Formik
          initialValues={initialValues}
          // onSubmit={(values, actions) => {
          //   setTimeout(() => {
          //     alert(JSON.stringify(reconstructValues(values), null, 2))
          //     actions.setSubmitting(false)
          //   }, 1000)
          // }}
          onSubmit={handleSubmit(app.closeModal, app.useToast)}
          validationSchema={InventoryValidationSchema}
          render={({ values }) => (
            <Form autoComplete="off">
              <Columns className="is-centered mx-1">
                <Column className="is-12">
                  {/*modalType === "adjusted" && (
                    <p className="mb-2">
                      Please adjust if 
                    </p>
                  )*/}
                  {productTitle ? (
                    <Input
                      name="productTitle"
                      label="Product Name"
                      isDisabled
                    />
                  ) : (
                    <SearchBar
                      label="Medicine/Supply"
                      endpoint="inventory/fetch_products"
                      resultDisplay={["title"]}
                      additionalParams={`price_list=clinics_default`}
                      callbackFunction={result => {
                        setCurrentProduct(result.itemCode);
                      }}
                      name="productTitle"
                      type="text"
                      isRequired
                    />
                  )}
                  <PureInventoryAdjustmentForms
                    modalType={modalType}
                    tallyLabel={tallyLabel}
                    values={values}
                    finalTally={finalTally}
                    computeFinalTally={computeFinalTally}
                    currentTally={currentTally}
                  />
                  {/* Confirm Adjustment */}
                  <button
                    type="submit"
                    className={`button has-text-weight-bold is-${buttonColor} is-pulled-right ${isLoading &&
                      "is-loading"}`}
                  >
                    Confirm adjustment
                  </button>
                </Column>
              </Columns>
            </Form>
          )}
        />
      )}
    </AppContext.Consumer>
  );
};

export default InventoryModal;
