import React, { useContext, Fragment } from "react";
import classNames from "classnames";

import DataTable from "../../Templates/DataTable";
import AdjustmentRow from "./AdjustmentRow";
import AppContext from "../../../context/AppContext";
import InventoryModal from "../InventoryModal";
import Loader from "../../Templates/Loader";

import styles from "../inventory.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCapsules, faFirstAid } from "@fortawesome/free-solid-svg-icons";
import ReplenishmentRow from "./ReplenishmentRow";

const AdjustmentTable = ({
  inventory,
  activeTab,
  setActiveTab,
  isLoading,
  loadInventory,
  setLoadingIndex,
  loadingIndex,
  clinicId,
  isAdmin,
  view,
}) => {
  const REPLENISHMENT_TITLES = [
    "Item",
    "Daily Average",
    "Quantity Remaining",
    "Days Remaining",
    "3-Month Replenishment",
    "6-Month Stock",
  ];
  const ADJUSTMENT_TITLES = [
    "Item",
    "Unit of Measurement",
    "Earliest Expiry Date / Date Defective",
    "Start of the Month Count",
    "Actual Count",
  ];

  const appContext = useContext(AppContext);

  const RenderAdjustmentRow = ({ item, index }) => (
    <AdjustmentRow
      item={item}
      index={index}
      isLoading={loadingIndex === index}
      callbackFunction={() => {
        appContext.useModal(
          <InventoryModal
            tallyName="manualTally"
            modalType="adjusted"
            tallyLabel="Manual Tally"
            buttonColor="light"
            productTitle={item.productTitle}
            expectedCount={item.expectedCount}
            productCode={item.productCode}
            actualCount={item.actualCount}
            earliestExpiry={item.earliestExpirationDate}
            loadInventory={loadInventory}
            setLoadingIndex={setLoadingIndex}
            currentIndex={index}
            clinicId={clinicId}
          />,
          "primary",
          "MANUAL INVENTORY"
        );
      }}
    />
  );

  const RenderReplenishmentRow = ({ item, index }) => (
    <ReplenishmentRow
      item={item}
      index={index}
      isLoading={loadingIndex === index}
    />
  );

  return (
    <Fragment>
      <div className="tabs is-boxed">
        <ul>
          <li
            className={classNames({
              "is-active": activeTab === "medicines",
            })}
            onClick={() => setActiveTab("medicines")}
          >
            <span>
              <FontAwesomeIcon icon={faCapsules} className="mr-1" />
              Medicine Inventory
            </span>
          </li>
          <li
            className={classNames({
              "is-active": activeTab === "supplies",
            })}
            onClick={() => setActiveTab("supplies")}
          >
            <span>
              <FontAwesomeIcon icon={faFirstAid} className="mr-1" />
              Supplies Inventory
            </span>
          </li>
        </ul>
      </div>

      {isLoading ? (
        <Loader />
      ) : !!inventory && inventory.length > 0 ? (
        <DataTable
          headerTitles={
            isAdmin && view === "replenish"
              ? REPLENISHMENT_TITLES
              : ADJUSTMENT_TITLES
          }
          headerStyles="has-text-centered"
        >
          {!!isAdmin && view === "replenish"
            ? inventory.map((item, index) => (
                <RenderReplenishmentRow item={item} index={index} />
              ))
            : inventory.map((item, index) => (
                <RenderAdjustmentRow item={item} index={index} />
              ))}
        </DataTable>
      ) : (
        <p
          className={classNames(
            "has-text-centered pt-2",
            styles.emptyInventoryMessage
          )}
        >
          No medicines logged.
        </p>
      )}
      <br className="mb-5" />
    </Fragment>
  );
};

export default AdjustmentTable;
