import React, { Fragment, useEffect, useContext, useReducer } from "react";
import Menu from "./Menu";
import AdjustmentTable from "./AdjustInventory/AdjustmentTable";

import api from "../../services/api";
import AppContext from "../../context/AppContext";

function reducer(state, action) {
  switch (action.type) {
    case "setInventory":
      return {
        ...state,
        inventory: action.payload,
      };
    case "setSearchParams":
      return { ...state, searchParams: action.payload };
    case "setIsLoading":
      return { ...state, isLoading: action.payload };
    case "setLoadingIndex":
      return { ...state, loadingIndex: action.payload };
    default:
      throw new Error();
  }
}

const InventoryLogs = ({ clinicId, isAdmin, view }) => {
  const initialState = {
    inventory: [],
    searchParams: {
      type: 1, // 1 = medicines, 2 = supplies
      clinic: clinicId,
    },
    activeTab: "medicines",
    isLoading: true,
    loadingIndex: -1,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const appContext = useContext(AppContext);

  const loadInventory = updated => {
    if (!updated) dispatch({ type: "setIsLoading", payload: true }); // for that feature where only adjusted row loads, not entire table
    let apiParams = new URLSearchParams(state.searchParams);
    const endpoint =
      !!isAdmin && view === "replenish"
        ? "/inventory/replenishment"
        : "/inventory/adjustments";
    api.get(`${endpoint}/?${apiParams.toString()}`).then(response => {
      dispatch({ type: "setInventory", payload: response.data });
      dispatch({ type: "setIsLoading", payload: false });
      dispatch({ type: "setLoadingIndex", payload: -1 });
    });
  };

  // We load the inventory on mount...
  useEffect(() => {
    if (!!clinicId) loadInventory();
  }, []);
  // ...and whenever the search params change (e.g. search, active tab), or view changes (admin feature, e.g. replenish or adjust)
  useEffect(() => {
    loadInventory();
  }, [state.searchParams, view]);

  useEffect(() => {
    dispatch({
      type: "setSearchParams",
      payload: { ...state.searchParams, clinic: clinicId },
    });
  }, [clinicId]);

  const searchData = search => {
    dispatch({
      type: "setSearchParams",
      payload: { ...state.searchParams, search: search },
    });
  };

  const resetSearch = values => {
    if (values.product) values.product = "";
    let tempSearchParams = { ...state.searchParams };
    delete tempSearchParams.search;
    dispatch({ type: "setSearchParams", payload: tempSearchParams });
  };

  const changeTabs = tabName => {
    dispatch({
      type: "setSearchParams",
      payload: { ...state.searchParams, type: tabName === "medicines" ? 1 : 2 },
    });
  };

  const setLoadingIndex = index => {
    // for that feature where only the adjusted row loads, not the whole table
    dispatch({
      type: "setLoadingIndex",
      payload: index,
    });
  };
  return (
    <Fragment>
      {!isAdmin && (
        <Menu
          searchData={searchData}
          resetSearch={resetSearch}
          appContext={appContext}
        />
      )}
      <AdjustmentTable
        inventory={state.inventory}
        loadInventory={loadInventory}
        activeTab={state.searchParams.type === 1 ? "medicines" : "supplies"}
        setActiveTab={changeTabs}
        isLoading={state.isLoading}
        loadingIndex={state.loadingIndex}
        setLoadingIndex={setLoadingIndex}
        clinicId={clinicId}
        isAdmin={isAdmin}
        view={view}
      />
    </Fragment>
  );
};

export default InventoryLogs;
