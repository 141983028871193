import React from "react";
import classNames from "classnames";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from "../inventory.module.scss";

const Loader = () => (
  <span className="has-text-centered">
    <FontAwesomeIcon icon={faSpinner} spin />
  </span>
);

const AdjustmentRow = ({ item, index, callbackFunction, isLoading }) => {
  let {
    productTitle,
    unitOfMeasurement,
    startOfTheMonth,
    actualCount,
    clinicStaff,
    lastUpdated,
    earliestExpirationDate,
  } = item;

  return (
    <tr className="is-selectable" onClick={callbackFunction}>
      <td className={classNames(styles.medicine)}>{productTitle}</td>
      <td className="has-text-centered">{unitOfMeasurement}</td>
      <td className="has-text-centered">
        {isLoading ? (
          <Loader />
        ) : earliestExpirationDate ? (
          moment(earliestExpirationDate).format("MMM DD YYYY")
        ) : (
          "-"
        )}
      </td>
      <td className="has-text-centered">
        {isLoading ? <Loader /> : startOfTheMonth}
      </td>
      <td
        className="has-text-centered tooltip"
        data-tooltip={`Last updated on ${moment(lastUpdated).format(
          "MMM DD YYYY, hh:mm a"
        )} by ${clinicStaff ? `${clinicStaff}` : "admin"}.`}
      >
        {isLoading ? <Loader /> : actualCount}
      </td>
    </tr>
  );
};

export default AdjustmentRow;
